import React from 'react';
import { useNavigate } from 'react-router-dom';

import { hash } from '@onesy/utils';
import { Interaction, Line, ListItem, Type, useSubscription } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';

import IconMaterialOpenInNew from '@onesy/icons-material-rounded-react/IconMaterialOpenInNewW100';

import { ReactComponent as LogoWebsite } from 'assets/svg/logos/logo-website.svg';
import { ReactComponent as LogoTask } from 'assets/svg/logos/logo-task.svg';
import { ReactComponent as LogoNote } from 'assets/svg/logos/logo-note.svg';
import { ReactComponent as LogoUrlShortener } from 'assets/svg/logos/logo-url-shortener.svg';
import { ReactComponent as LogoChat } from 'assets/svg/logos/logo-chat.svg';
import { ReactComponent as LogoContact } from 'assets/svg/logos/logo-contact.svg';
import { ReactComponent as LogoPersonalTrainer } from 'assets/svg/logos/logo-personal-trainer.svg';

import { AuthService, StorageService } from 'services';
import { getAppRootURL } from 'utils';
import { ISignedIn } from 'types';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  items: {
    padding: 16
  },

  item: {
    position: 'relative',
    width: 94,
    padding: 8,
    borderRadius: 8,
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),
    userSelect: 'none',

    '& svg': {
      width: 41
    },

    '& .onesy-Type-root': {
      width: '100%',
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  mainLogo: {
    height: 44,
    color: theme.palette.text.default.primary
  }
}), { name: 'onesy-route-Main' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();

  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const to = React.useCallback((value: string) => {
    navigate(value);
  }, []);

  const is = signedIn?.user?.is;

  const onOpenLink = React.useCallback((url: string) => {
    window.open(url);
  }, []);

  const apps = React.useMemo(() => {
    return [
      ...(is?.user ? [{
        name: 'Website',
        logo: LogoWebsite,
        to: getAppRootURL('app_website', '/websites', signedIn),
        props: {
          className: 'onesy-logo-website'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Chat',
        logo: LogoChat,
        to: getAppRootURL('app_chat', '/chats', signedIn),
        props: {
          className: 'onesy-logo-chat'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'URL Shortener',
        logo: LogoUrlShortener,
        to: getAppRootURL('app_urlShortener', '/url-shorteners', signedIn),
        props: {
          className: 'onesy-logo-url-shortener'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Notes',
        logo: LogoNote,
        to: getAppRootURL('app_note', '/notes', signedIn),
        props: {
          className: 'onesy-logo-note'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Tasks',
        logo: LogoTask,
        to: getAppRootURL('app_task', '/tasks', signedIn),
        props: {
          className: 'onesy-logo-task'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Contact',
        logo: LogoContact,
        to: getAppRootURL('app_contact', '/contacts', signedIn),
        props: {
          className: 'onesy-logo-contact'
        }
      }] : [])
    ];
  }, [hash(is)]);

  const mainApps = React.useMemo(() => {
    return [
      {
        name: 'Personal Trainer',
        logo: LogoPersonalTrainer,
        to: `${config.value.apps.personal_trainer.url}?onesy_token=${StorageService.get('token')}`,
        props: {
          className: 'onesy-logo-personal-trainer'
        }
      }
    ];
  }, []);

  return (
    <Line
      ref={ref}

      gap={2}

      direction='column'

      justify='center'

      align='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Type
        version='h3'

        align='center'
      >
        Use an app
      </Type>

      <Line
        gap={0}

        direction='column'

        justify='center'

        align='center'

        fullWidth
      >
        <Line
          gap={1}

          direction='row'

          justify='center'

          align='center'
        >
          {mainApps.map((item, index) => (
            <ListItem
              key={index}

              onClick={() => onOpenLink(item.to)}

              primary={(
                <Line
                  gap={1}

                  direction='row'

                  align='center'

                  style={{
                    color: theme.palette.text.default.primary
                  }}
                >
                  <item.logo
                    className={classes.mainLogo}
                  />

                  <IconMaterialOpenInNew
                    color='inherit'

                    size='small'
                  />
                </Line>
              )}

              size='small'

              button

              noBackground

              Component='div'

              {...item.props}
            />
          ))}
        </Line>

        <Line
          gap={0}

          direction='row'

          justify='center'

          wrap='wrap'

          className={classes.items}
        >
          {apps?.map((item: any) => (
            <Line
              key={item.name}

              gap={1}

              align='center'

              onClick={() => to(item.to)}

              {...item.props}

              className={classNames([
                item.props?.className,
                classes.item
              ])}
            >
              <item.logo />

              <Type
                version='b2'

                align='center'

                color='default'
              >
                {item.name}
              </Type>

              <Interaction />
            </Line>
          ))}
        </Line>
      </Line>
    </Line>
  );
});

export default Element;
