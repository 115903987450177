import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, Medias, Menu, MenuItem, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { OnesyDate } from '@onesy/date';
import { Task } from '@onesy/api';

import IconMaterialFlag from '@onesy/icons-material-rounded-react/IconMaterialFlagW100Filled';

import { Button, SmartTextField, SpeechToText, TextToSpeech, AudioRecorder, CalendarMenu } from 'ui';
import { AuthService, MediaService, TaskService } from 'services';
import { audioFix, getDate, getElementText, getErrorMessage, mediasToValue, priorityToColor } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    '& .onesy-Button-root': {
      flex: '0 0 auto'
    },

    '& .onesy-TextField-input-wrapper': {
      padding: '0 !important',
      height: 'auto !important'
    },

    '& .onesy-Type-root': {
      whiteSpace: 'normal'
    }
  },

  main: {
    height: 0,
    maxHeight: '54vh',
    overflowY: 'auto',

    '& .onesy-TextField-border': {
      boxShadow: 'none'
    }
  },

  audio: {
    maxWidth: '100%',
    maxHeight: 40
  },

  footer: {
    overflowX: 'auto'
  }
}), { name: 'onesy-TaskAdd' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const refs = {
    name: React.useRef<HTMLElement>(undefined),
    value: React.useRef<HTMLElement>(undefined),
    form: React.useRef<any>(undefined),
    original: React.useRef<any>(undefined)
  };

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: '',
        max: 1400,
        required: true,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'value': {
        name: 'Description',
        value: '',
        max: 14000,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 14000 characters'
        }
      },
      'ends_at': {
        name: 'Due date',
        is: 'number',
        value: OnesyDate.milliseconds
      },
      'priority.name': {
        name: 'Priority',
        value: 'default',
        is: 'string',
        in: ['default', 'low', 'medium', 'important']
      },
      'priority.value': {
        name: 'Priority value',
        value: 1,
        is: 'number',
        min: 1,
        max: 1000
      },
      'repeat': {
        name: 'Repeat',
        is: 'object',
        value: {}
      },
      'reminders': {
        name: 'Reminders',
        is: 'array',
        of: 'object'
      },
      'media': {
        name: 'Media',
        value: [],
        is: 'array',
        of: 'object'
      }
    },

    autoValidate: true
  });

  refs.form.current = form;

  const onClose = React.useCallback((event?: MouseEvent) => {
    if (is('function', onClose_)) onClose_(event);

    setTimeout(() => {
      refs.form.current.clear();
    }, 1400);
  }, [onClose_]);

  const isValid = form.value.name || form.value.value;

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid || !isValid) return;

    setLoading(true);

    const value = refs.form.current.value;

    const body: Partial<Task> = {
      name: value.name,
      done: value.done,
      ends_at: value.ends_at,
      repeat: value.repeat,
      priority: value.priority,
      media: value.media
    };

    const result = await TaskService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Task added`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [isValid, form, onConfirm, onClose]);

  const getMenuItemsPriority = () => {
    const values = [
      { name: 'Default', onClick: () => { form.onChange([['priority.name', 'default'], ['priority.value', 1]]); } },
      { name: 'Low', onClick: () => { form.onChange([['priority.name', 'low'], ['priority.value', 2]]); } },
      { name: 'Medium', onClick: () => { form.onChange([['priority.name', 'medium'], ['priority.value', 3]]); } },
      { name: 'Important', onClick: () => { form.onChange([['priority.name', 'important'], ['priority.value', 4]]); } }
    ];

    return values.map((item: any, index: number) => (
      <MenuItem
        key={index}

        start={(
          <IconMaterialFlag
            color={priorityToColor(item.name?.toLowerCase())}
          />
        )}

        primary={(
          <Type
            version='b3'
          >
            {item.name}
          </Type>
        )}

        size='small'

        onClick={item.onClick}

        menuCloseOnClick

        button
      />
    ));
  };

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const {
      blob: media,
      duration
    } = await audioFix(value);

    console.log('onAudioRecorderConfirm audioFix', media, duration);

    if (!media) {
      console.log('Media make error', media, duration);

      snackbars.add({
        primary: 'Voice record issue. Your device might not support voice recording properly',
        color: 'error'
      });

      setLoading(false);

      return;
    }

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${getDate(undefined, 'entire')}`;

    // meta
    const meta: any = {};

    if (duration) meta.duration = duration;

    const result = await MediaService.add({
      name,

      meta,

      app: 'task',

      // media
      media
    }, { query: { browser: true } });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the note
      const medias = refs.form.current.values.media.value || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: OnesyDate.milliseconds
      }));

      refs.form.current.onChange('media', medias);
    }

    setLoading(false);
  }, [onConfirm]);

  const onSpeechToTextStart = React.useCallback(() => {
    refs.original.current = textToInnerHTML(refs.form.current.values.value.value);
  }, []);

  const onSpeechToTextStop = React.useCallback(() => {
    refs.original.current = '';
  }, []);

  const onSpeechToTextUpdate = React.useCallback((valueNew: any) => {
    refs.value.current!.innerHTML = refs.original.current;

    const div = window.document.createElement('div');

    div.innerHTML = valueNew;

    refs.value.current!.append(div);

    refs.form.current.onChange('value', innerHTMLToText(refs.value.current!.innerHTML));
  }, []);

  const propsOther: any = {
    size: 'small'
  };

  return (
    <Line
      gap={2}

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={4}

        flex

        fullWidth

        className={classes.main}
      >
        <Line
          gap={1.5}

          justify='unset'

          align='unset'

          flex

          fullWidth
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <CalendarMenu
              value={form.value}

              onChange={form.onChange}

              closeOnChange={false}

              RepeatProps={{
                fromOptions: ['end', 'done']
              }}
            />

            <Menu
              menuItems={getMenuItemsPriority()}
            >
              <IconButton
                size='small'
              >
                <IconMaterialFlag
                  color={priorityToColor(form.values['priority.name'].value)}

                  size='regular'
                />
              </IconButton>
            </Menu>
          </Line>

          <Line
            gap={1}

            fullWidth
          >
            <SmartTextField
              ref={refs.name}

              placeholder='Name'

              onChange={(valueNew: string) => form.onChange('name', textToInnerHTML(valueNew))}

              additional={{
                version: 't2'
              }}

              style={{
                whiteSpace: 'normal'
              }}
            />

            <SmartTextField
              ref={refs.value}

              placeholder='Description'

              onChange={(valueNew: string) => form.onChange('value', valueNew)}

              additional={{
                version: 'b2'
              }}

              edit

              multiline

              mention
            />
          </Line>
        </Line>

        <Medias
          values={form.values.media.value?.map((item: any) => ({
            value: item
          }))}
        />
      </Line>

      <Line
        direction='row'

        justify='space-between'

        fullWidth

        className={classes.footer}
      >
        <Button
          version='text'

          color='inherit'

          onClick={onClose}

          disabled={loading === true}

          {...propsOther}
        >
          Close
        </Button>

        <Line
          gap={1}

          direction='row'

          align='center'
        >
          <SpeechToText
            onData={onSpeechToTextUpdate}

            onChange={onSpeechToTextUpdate}

            onStart={onSpeechToTextStart}

            onStop={onSpeechToTextStop}

            language={signedIn?.organization?.personalization?.settings?.languages?.app}

            disabled={loading}

            IconProps={{
              size: 'regular'
            }}

            {...propsOther}
          />

          {!!form.value.value?.length && (
            <TextToSpeech
              read={getElementText(textToInnerHTML(form.value.value))}

              language={signedIn?.organization?.personalization?.settings?.languages?.app}

              IconProps={{
                size: 'regular'
              }}

              {...propsOther}
            />
          )}

          <AudioRecorder
            onConfirm={(value: Blob) => onAudioRecorderConfirm(value)}

            disabled={loading}

            IconProps={{
              size: 'regular'
            }}

            {...propsOther}
          />

          <Button
            color={theme.palette.light ? 'default' : 'inverted'}

            elevation={false}

            onClick={onNext}

            {...propsOther}
          >
            Next
          </Button>
        </Line>
      </Line>
    </Line>
  );
});

export default Element;
