/* eslint-disable import/no-anonymous-default-export */

class Config {
  public values = {
    onesy_token: '',

    apis: {
      api: { url: '' },
      api_media_read: { url: '' },
      api_media_write: { url: '' },
      sockets: { url: '' },
      app: { url: '' },
      websites: { url: '' },
      public: { url: '' },
      website: { url: '' },
      personal_trainer: { url: '' },
      personal_trainer_client: { url: '' }
    },

    services: {
      google: {
        client_id: ''
      },

      stripe: {
        public_key: ''
      }
    },

    resolveOnError: true
  };

  get value() {
    const env = process.env.NODE_ENV;

    return {
      onesy_token: process.env.REACT_APP_ONESY_TOKEN || this.values.onesy_token,

      env,

      production: ['prod', 'production'].includes(env),

      apps: {
        api: { url: process.env.REACT_APP_APIS_API || this.values.apis.api.url },
        api_media_read: { url: process.env.REACT_APP_APIS_API_MEDIA_READ || this.values.apis.api_media_read.url },
        api_media_write: { url: process.env.REACT_APP_APIS_API_MEDIA_WRITE || this.values.apis.api_media_write.url },
        sockets: { url: process.env.REACT_APP_APIS_SOCKETS || this.values.apis.sockets.url },
        app: { url: process.env.REACT_APP_APP || this.values.apis.app.url },
        websites: { url: process.env.REACT_APP_WEBSITES || this.values.apis.websites.url },
        public: { url: process.env.REACT_APP_PUBLIC || this.values.apis.public.url },
        website: { url: process.env.REACT_APP_WEBSITE || this.values.apis.website.url },
        personal_trainer: { url: process.env.REACT_APP_PERSONAL_TRAINER || this.values.apis.personal_trainer.url },
        personal_trainer_client: { url: process.env.REACT_APP_PERSONAL_TRAINER_CLIENT || this.values.apis.personal_trainer_client.url }
      },

      services: {
        google: {
          client_id: process.env.REACT_APP_SERVICES_GOOGLE_CLIENT_ID,

          map: {
            api_key: process.env.REACT_APP_SERVICES_GOOGLE_MAP_API_KEY
          }
        },

        stripe: {
          public_key: process.env.REACT_APP_SERVICE_STRIPE_PUBLIC_KEY
        },

        sentry: {
          dsn: process.env.REACT_APP_SERVICE_SENTRY_DSN
        }
      },

      webPush: {
        vapid: {
          public_key: process.env.REACT_APP_WEB_PUSH_VAPID_PUBLIC_KEY
        }
      }
    };
  }

  private array(value: string) {
    if (!value) return;

    return (value || '').split(',').map((item: any) => item.trim()).filter(Boolean);
  }
}

export default new Config();
