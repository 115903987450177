import React from 'react';

import { download, getCountry, innerHTMLToText, is, isValid, stringToColor, textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, Link, PieChart, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { ValidationError } from '@onesy/errors';
import { OnesyDate, format } from '@onesy/date';
import { Analytic } from '@onesy/api';

import IconMaterialIosShare from '@onesy/icons-material-rounded-react/IconMaterialIosShareW100';
import IconMaterialRefresh from '@onesy/icons-material-rounded-react/IconMaterialRefreshW100';

import { AnalyticValue, Analytics, Input, Inputs, ModalForm, Select, TextField } from 'ui';
import { AnalyticService, AppService, URLShortenerService } from 'services';
import { getDate, getErrorMessage } from 'utils';
import { IQuerySubscription } from 'types';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  surface: {
    '&.onesy-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  chart: {
    width: '100%'
  },

  ...theme.classes(theme)
}), { name: 'onesy-UrlShortener' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const queryURLShortenerAnalytics = useSubscription<IQuerySubscription>(AnalyticService.queryURLShortenerAnalytics);

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);
  const [analyticsPer, setAnalyticsPer] = React.useState<any>('');

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'url': {
        name: 'URL',
        value: object?.url,
        required: true,
        method: [
          (value_: string) => {
            const response = isValid('url-path', value_?.startsWith('/') ? value_ : `/${value_}`);

            if (!response) throw new ValidationError(`URL has to be a valid url path`);
          }
        ]
      },
      'to.url': {
        name: 'To URL',
        value: object?.to?.url,
        required: true,
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form),
    queryURLShortenerAnalytics: React.useRef(queryURLShortenerAnalytics)
  };

  refs.form.current = form;

  refs.queryURLShortenerAnalytics.current = queryURLShortenerAnalytics;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const dates = React.useMemo(() => {
    const today = OnesyDate.utc;

    return [
      { name: 'Total', value: '', unit: 'total' },
      { name: 'Hour', value: format(today, 'YYYY-MM-DD HH'), unit: 'hour' },
      { name: 'Today', value: format(today, 'YYYY-MM-DD'), unit: 'day' },
      { name: 'Month', value: format(today, 'YYYY-MM'), unit: 'month' },
      { name: 'Year', value: format(today, 'YYYY'), unit: 'year' }
    ];
  }, []);

  const init = React.useCallback(async () => {
    if (object) {
      const result = await AnalyticService.queryURLShortenerAnalytics.value!.query({
        query: {
          query: {
            user: null,
            version: [`urlShorteners-${object?.id}-visit`, `urlShorteners-${object?.id}-visit-location`],
            date: dates.map((item: any) => item.value)
          }
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
    }
  }, [object, dates]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onRefresh = React.useCallback(async () => {
    setLoading('refresh');

    await AnalyticService.queryURLShortenerAnalytics.value!.refetch();

    setLoading(false);
  }, []);

  const onExport = React.useCallback(async () => {
    const value = queryURLShortenerAnalytics?.response;

    let values = is('array', value) ? value : [value];

    const properties = ['value', 'date', 'version', 'added_at', 'updated_at'];

    values = values.map((item: any) => {
      const object: any = {};

      properties.forEach((property: any) => {
        if (item[property]) object[property] = item[property];
      });

      return object;
    });

    const valueExport = {
      version: 'export',
      objects: 'url-shortener-analytics',
      value: values
    };

    download(`Form responses ${getDate(undefined, 'entire')}`, JSON.stringify(valueExport, null, 2), 'application/json');
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      active: true
    };

    const result = !object?.id ? await URLShortenerService.add(body) : await URLShortenerService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Url shortener ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeAnalyticsPer = React.useCallback((value: any) => {
    setAnalyticsPer(value);
  }, []);

  const getAnalytic = (version: string, date: any, unit?: string): any => {
    return (queryURLShortenerAnalytics?.response || []).find((item: Analytic) => item.version === version && (date !== undefined ? date === item.date : item.unit === unit));
  };

  const getPieChart = () => {
    const analytic = getAnalytic(`urlShorteners-${object?.id}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <PieChart
          title='Clicks per country'

          subtitle='In %'

          values={countries.map((item: any) => ({
            color: stringToColor(getCountry(item)?.name) as any,
            name: getCountry(item)?.name,
            values: [analytic.value[item]]
          }))}

          className={classNames([
            classes.chart,
            classes.pieChart,
            countries.length === 1 && classes.pieChart100
          ])}
        />
      );
    }

    return null;
  };

  const getCountryTable = () => {
    const analytic = getAnalytic(`urlShorteners-${object?.id}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <Table>
          <TableHead>
            <TableRow>
              {['Name', 'Visits'].map((item: string, index: number) => (
                <TableCell
                  key={index}
                >
                  <Type
                    version='b2'
                  >
                    {item}
                  </Type>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {countries.map((item: any, index: number) => (
              <TableRow
                key={index}
              >
                <TableCell
                  key={index}
                >
                  <Type
                    version='b2'
                  >
                    {getCountry(item)?.name}
                  </Type>
                </TableCell>

                <TableCell
                  key={index}
                >
                  <Type
                    version='b2'
                  >
                    {analytic.value[item]}
                  </Type>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return null;
  };

  const analyticSelected = getAnalytic(`urlShorteners-${object?.id}-visit-location`, analyticsPer);

  const iconProps: any = {
    size: 'large'
  };

  const modal: any = {
    read: (
      <Line
        gap={5}

        fullWidth
      >
        <Line
          gap={2}

          fullWidth
        >
          {object?.description && (
            <Type
              version='b1'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object?.description)
              }}
            />
          )}

          <Input
            name='Short URL'
          >
            <Link
              href={`${config.value.apps.public.url}/url/${object?.url}`}

              target='blank'

              version='t2'
            >
              {object?.url}
            </Link>
          </Input>

          <Input
            name='Link for'
          >
            <Link
              href={object?.to.url}

              target='blank'

              version='t2'
            >
              {object?.to.url}
            </Link>
          </Input>
        </Line>

        <Line
          fullWidth
        >
          <Input
            gap={1}

            name='Clicks'

            fullWidth

            end={(
              <Line
                gap={1}

                direction='row'

                align='center'

                justify='flex-end'

                fullWidth
              >
                <Tooltip
                  name='Export'
                >
                  <IconButton
                    onClick={onExport}

                    disabled={loading}
                  >
                    <IconMaterialIosShare {...iconProps} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  name='Refresh'
                >
                  <IconButton
                    onClick={onRefresh}

                    disabled={loading}
                  >
                    <IconMaterialRefresh {...iconProps} />
                  </IconButton>
                </Tooltip>
              </Line>
            )}
          >
            <Analytics
              align='flex-end'
            >
              {dates.map((item, index: number) => (
                <AnalyticValue
                  key={index}

                  name={item.name.trim()}

                  value={getAnalytic(`urlShorteners-${object?.id}-visit`, undefined, item.unit)?.value || 0}
                />
              ))}
            </Analytics>
          </Input>

          <Input
            gap={1}

            name='Analytics'

            fullWidth

            style={{
              overflowX: 'hidden'
            }}
          >
            <Line
              align='flex-end'

              fullWidth
            >
              <Select
                valueDefault=''

                value={analyticsPer}

                onChange={onChangeAnalyticsPer}

                options={dates}
              />
            </Line>

            <Analytics
              gap={2}

              align='center'

              direction='column'

              style={{
                padding: '16px 8px 8px'
              }}
            >
              {!analyticSelected && (
                <Line
                  align='center'

                  justify='center'

                  style={{
                    height: 140
                  }}
                >
                  <Type
                    version='b1'

                    align='center'
                  >
                    No visits recorded at the moment
                  </Type>
                </Line>
              )}

              {getPieChart()}

              {getCountryTable()}
            </Analytics>
          </Input>
        </Line>
      </Line>
    ),

    write: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew)}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={4}

            multiline

            edit

            fullWidth
          />
        </Input>

        <Input
          name='Short URL'
        >
          <TextField
            placeholder='i-love-trees'

            valueDefault={form.values.url?.value || ''}

            onChange={(valueNew: string) => form.onChange('url', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values.url.error}

            helperText={form.values.url.error}

            fullWidth
          />
        </Input>

        <Input
          name='Redirect to'

          description='Link to redirect to, once some clicks on the short URL'
        >
          <TextField
            placeholder='https://example.com/shop/my-product'

            valueDefault={form.values['to.url']?.value || ''}

            onChange={(valueNew: string) => form.onChange('to.url', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values['to.url'].error}

            helperText={form.values['to.url'].error}

            type='url'

            fullWidth
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading === true}
    />
  );
});

export default Element;
