import React from 'react';

import { classNames } from '@onesy/style-react';

import { FormUserGroup, Items, NoResults, Page } from 'ui';
import { UserGroupService } from 'services';
import ItemEmployeeGroup from './Item';

const Element = React.forwardRef(() => {
  const search: any = [
    {
      name: 'Name',
      type: 'text',
      property: 'name'
    },
    {
      name: 'Added at',
      type: 'date',
      property: 'added_at'
    }
  ];

  return (
    <Page
      name='Employee groups'

      singular='employee group'

      plural='employee groups'

      service={UserGroupService}

      search={search}

      FormAdd={FormUserGroup}

      FormUpdate={FormUserGroup}

      className={classNames([
        'onesy-EmployeeGroups'
      ])}
    >
      {({ response }) => {
        if (!response?.length) return <NoResults />;

        return (
          <Items
            service={UserGroupService}
          >
            {response?.map((item: any, index: number) => (
              <ItemEmployeeGroup
                key={index}

                object={item}
              />
            ))}
          </Items>
        );
      }}
    </Page>
  );
});

export default Element;
