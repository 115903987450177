import React from 'react';

import { arrayMoveItem, getID } from '@onesy/utils';
import { IconButton, Line, Tooltip, Type } from '@onesy/ui-react';

import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';

import { IPage } from 'ui/layout/Page';
import { Action, Items } from 'ui/element';

export interface IPages extends IPage {
  form: any;

  prefix?: string;
}

const Element: React.FC<IPages> = React.forwardRef((props, ref: any) => {
  const {
    form,

    prefix: prefixProps = 'options.header.',

    className,

    ...other
  } = props;

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const prefix = `${prefixProps}menu`;

  const onAdd = React.useCallback(() => {
    const menuNew = [...(refs.form.current.values[prefix].value || [])];

    menuNew.push({
      id: getID(),
      name: 'Page',
      version: 'to',
      value: '/about',
      priority: 'regular'
    });

    refs.form.current.onChange(prefix, menuNew);
  }, []);

  const onMove = React.useCallback((indexPrevious: number, indexNew: number) => {
    const menuNew = [...(refs.form.current.values[prefix].value || [])];

    arrayMoveItem(menuNew, indexPrevious, indexNew);

    refs.form.current.onChange(prefix, menuNew);
  }, []);

  const onRemove = React.useCallback((index: number) => {
    const menuNew = [...(refs.form.current.values[prefix].value || [])];

    menuNew.splice(index, 1);

    refs.form.current.onChange(prefix, menuNew);
  }, [prefix]);

  const menu = form.values[prefix].value || [];

  return (
    <Line
      ref={ref}

      fullWidth

      {...other}
    >
      <Line
        gap={1}

        direction='row'

        align='center'

        justify='space-between'

        fullWidth
      >
        <Type
          version='h3'
        >
          Menu
        </Type>

        <Tooltip
          name='Add menu item'
        >
          <IconButton
            onClick={onAdd}
          >
            <IconMaterialAdd
              size='large'
            />
          </IconButton>
        </Tooltip>
      </Line>

      <Items>
        {menu.map((item, index: number) => (
          <Action
            item={item}

            menu={menu}

            form={form}

            prefix={prefix}

            property={index}

            index={index}

            onMove={onMove}

            onRemove={onRemove}

            important
          />
        ))}
      </Items>
    </Line>
  );
});

export default Element;
