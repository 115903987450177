import React from 'react';

import { setObjectValue } from '@onesy/utils';
import { Line, Type, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import { Input, NumericTextField, Select } from 'ui';
import { AuthService } from 'services';
import { optionsPayUnit } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    '& .onesy-TextField-input-wrapper, & .onesy-TextField-sufix': {
      paddingBlock: '4px !important'
    }
  }
}), { name: 'onesy-PayForm' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    object,

    onUpdate,

    rerender = true,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const currency = signedIn.organization?.settings?.currency || 'EUR';

  const update = React.useCallback((property: string, valueNew: any, propertyNested: any, other?: any) => {
    if (form) form.onChange(property, valueNew, propertyNested, other);
    else if (object !== undefined && onUpdate) {
      const pay = object || {};

      setObjectValue(pay, propertyNested, valueNew);

      onUpdate('pay', pay);
    }
  }, [form, object, onUpdate]);

  return (
    <Input
      name='Pay'

      endHeader={(
        <Line
          gap={1}

          direction='row'

          wrap='wrap'

          align='center'

          justify={{
            default: 'flex-start',
            1100: 'flex-end'
          }}

          fullWidth={{
            default: false,
            1100: true
          }}

          flexNo
        >
          <NumericTextField
            version='text'

            placeholder='100'

            {...rerender ? {
              value: object !== undefined ? object?.value : form?.values?.pay?.value?.value
            } : {
              valueDefault: object !== undefined ? object?.value : form?.values?.pay?.value?.value
            }}

            onChange={(valueNew: any) => update('pay', valueNew, 'value')}

            min={0}

            sufix={currency}

            align='end'

            enabled

            minimal

            fullWidth

            style={{
              width: 'auto',
              minWidth: 94
            }}
          />

          <Type
            version='b1'

            weight={100}
          >
            per
          </Type>

          <Select
            version='text'

            {...rerender ? {
              value: object !== undefined ? object?.unit : form?.values?.pay?.value?.unit
            } : {
              valueDefault: object !== undefined ? object?.unit : form?.values?.pay?.value?.unit
            }}

            onChange={(valueNew: any) => update('pay', valueNew, 'unit')}

            options={optionsPayUnit}

            noSelectText='Unit'

            minimal

            fullWidth

            WrapperProps={{
              style: {
                width: 60,
                height: 'unset',
                minWidth: 'unset'
              }
            }}

            style={{
              width: 60,
              height: 'unset',
              minWidth: 'unset'
            }}
          />
        </Line>
      )}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    />
  );
});

export default Element;
