import React from 'react';

import { NumericTextField } from '@onesy/ui-react';
import { INumericTextField } from '@onesy/ui-react/NumericTextField/NumericTextField';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'onesy-NumericTextField' });

const Element = React.forwardRef((props: INumericTextField, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <NumericTextField
      ref={ref}

      version='outlined'

      size='small'

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'onesy-NumericTextField';

export default Element;
