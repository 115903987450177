import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Confirm, MainProgress } from '@onesy/ui-react';
import { Style, Theme, OnesyStyle, OnesyTheme, unit, prefix, sort, rtl, valueObject, makeClassName, colors } from '@onesy/style-react';
import { IThemeValue } from '@onesy/style-react/ThemeProvider/Theme';

import { MainLoader, Library, Snackbars } from 'ui';
import { FONT_FAMILY } from 'utils';

import App from './App';
import config from 'config';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const stripePromise = loadStripe(config.value.services.stripe.public_key as string) as any;

const valueStyle = new OnesyStyle();

valueStyle.plugins.add = [
  unit,
  prefix,
  sort,
  rtl,
  valueObject,
  {
    method: makeClassName,
    arguments: [
      {
        production: ['prod', 'production'].includes(process.env.NODE_ENV)
      }
    ]
  }
];

const maxWidth = 1440;

const valueTheme = new OnesyTheme({
  palette: {
    light: window.matchMedia('(prefers-color-scheme: light)').matches,

    color: {
      onesy: {
        main: '#5FE11E'
      },
      'personal-trainer': {
        main: '#b6ff00'
      },
      website: {
        main: '#1EE155'
      },
      task: {
        main: '#00E0FF'
      },
      note: {
        main: '#F1E80E'
      },
      'url-shortener': {
        main: '#AD00FF'
      },
      chat: {
        main: '#0094FF'
      },
      contact: {
        main: '#1EE1E1'
      },

      primary: {
        main: '#5FE11E'
      },
      secondary: {
        main: colors.yellow[500]
      },
      tertiary: {
        main: colors.lightblue[500]
      },
      quaternary: {
        main: colors.amber[500]
      }
    }
  },

  typography: {
    font_family: {
      primary: FONT_FAMILY.primary,
      secondary: FONT_FAMILY.secondary,
      tertiary: FONT_FAMILY.tertiary
    },

    font_size: {
      html: 17
    },

    values: {
      d1: { fontFamily: FONT_FAMILY.primary },

      d2: { fontFamily: FONT_FAMILY.primary },

      d3: { fontFamily: FONT_FAMILY.primary },

      h1: { fontFamily: FONT_FAMILY.primary },

      h2: { fontFamily: FONT_FAMILY.primary },

      h3: { fontFamily: FONT_FAMILY.primary },

      t1: { fontFamily: FONT_FAMILY.primary },

      t2: { fontFamily: FONT_FAMILY.primary },

      t3: { fontFamily: FONT_FAMILY.primary },

      l1: { fontFamily: FONT_FAMILY.secondary },

      l2: { fontFamily: FONT_FAMILY.secondary },

      l3: { fontFamily: FONT_FAMILY.secondary },

      b1: { fontFamily: FONT_FAMILY.secondary },

      b2: { fontFamily: FONT_FAMILY.secondary },

      b3: { fontFamily: FONT_FAMILY.secondary },

      m1: { fontFamily: FONT_FAMILY.tertiary },

      m2: { fontFamily: FONT_FAMILY.tertiary },

      m3: { fontFamily: FONT_FAMILY.tertiary }
    }
  },

  ui: {
    elements: {
      onesyMedias: {
        props: {
          default: {
            size: 'regular',
            noName: true
          }
        }
      },

      onesyAutoCompleteCurrency: {
        props: {
          default: {
            size: 'small'
          }
        }
      },

      onesyAutoCompleteGooglePlaces: {
        props: {
          default: {
            size: 'small'
          }
        }
      },

      onesyModal: {
        props: {
          default: {
            size: 'regular'
          }
        }
      },

      onesyModalMain: {
        props: {
          default: {
            align: 'center'
          }
        }
      },

      onesyLink: {
        props: {
          default: {
            underline: 'hover'
          }
        }
      },

      onesyButton: {
        props: {
          default: {
            color: 'inherit'
          }
        }
      },

      onesyFab: {
        props: {
          default: {
            color: 'primary'
          }
        }
      },

      onesyIcon: {
        props: {
          default: {
            size: 'large'
          }
        }
      },

      onesyIconButton: {
        props: {
          default: {
            color: 'inherit'
          }
        }
      },

      onesyTooltip: {
        props: {
          default: {
            tonal: true,
            color: 'inverted'
          }
        }
      },

      onesyAutoComplete: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      onesyTextField: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      onesyColorTextField: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      onesySelect: {
        props: {
          default: {
            version: 'outlined'
          }
        }
      },

      onesyType: {
        props: {
          default: {
            color: 'inherit'
          }
        }
      },

      onesyDivider: {
        props: {
          default: {
            tonal: false
          }
        }
      },

      onesyFormRow: {
        props: {
          default: {
            fullWidth: false
          }
        }
      },

      onesyPieChart: {
        props: {
          default: {
            tonal: true,
            color: 'default'
          }
        }
      },

      onesyColumnChart: {
        props: {
          default: {
            tonal: true,
            color: 'default'
          }
        }
      }
    }
  },

  classes: (theme: IThemeValue) => ({
    optionsPage: {
      padding: '24px 4px',
      width: '100%',
      maxWidth,
      margin: '0 auto'
    },

    wrapper: {
      width: '100%',
      maxWidth,
      margin: '0 auto',
      padding: '16px 16px 100px'
    },

    wrapperLarge: {
      width: '100%',
      maxWidth: 1920,
      margin: '0 auto',
      padding: '16px 16px 100px'
    },

    page: {
      padding: 24,
      width: '100%',
      maxWidth,
      margin: '0 auto'
    },

    palette: {
      width: 20,
      height: 20,
      boxShadow: theme.palette.light ? '0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.11)' : '0px 1px 1px 0px rgba(255, 255, 255, 0.21), 0px 2px 1px -1px rgba(255, 255, 255, 0.12), 0px 1px 3px 0px rgba(255, 255, 255, 0.40)',
      borderRadius: '50%',
      flex: '0 0 auto',
      transition: theme.methods.transitions.make('transform'),

      '& > *': {
        width: '100% !important',
        height: 'calc(100% + 12px) !important'
      },

      '&:active': {
        transform: 'scale(0.94)'
      }
    },

    palette_size_small: {
      width: 16,
      height: 16
    },

    palette_size_regular: {
      width: 20,
      height: 20
    },

    palette_size_large: {
      width: 24,
      height: 24
    },

    emptyColor: {
      border: '2px solid transparent',
      backgroundImage: `linear-gradient(white, white), linear-gradient(to right, ${theme.palette.color.primary.main}, ${theme.palette.color.secondary.main})`,
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box'
    },

    autoVertical: {
      overflow: 'hidden auto'
    },

    autoHorizontal: {
      overflow: 'auto hidden'
    },

    actions: {
      paddingBlock: 6,
      overflow: 'auto hidden'
    },

    externalLinks: {
      overflow: 'auto hidden'
    },

    aspectRatioYoutube: {
      aspectRatio: '16 / 9',
      width: 440
    },

    aspectRatioVimeo: {
      aspectRatio: '16 / 9',
      width: 440
    },

    aspectRatioInstagram: {
      aspectRatio: '3 / 5.7',
      width: 340
    },

    aspectRatioTiktok: {
      aspectRatio: '3 / 6.84',
      width: 324
    },

    boxes: {
      padding: 24,
      overflow: 'auto hidden'
    },

    box: {
      padding: 24,
      width: 214,
      borderRadius: 24,
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      flex: '0 0 auto'
    },

    boxSmall: {
      minWidth: 134,
      padding: '8px 20px',
      borderRadius: 12,
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      flex: '0 0 auto'
    },

    pieChart100: {
      '& .onesy-Path-root': {
        strokeWidth: 0
      }
    },

    overflowY: {
      flex: '1 1 auto',
      height: 0,
      overflow: 'hidden auto'
    }
  }),

  maxWidth,
  minWidthInput: 184,
  input: 'clamp(140px, 340px, 100%)'
});

const root = ReactDOM.createRoot(
  document.getElementById('onesy-root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Style
      value={valueStyle}
    >
      <Theme
        value={valueTheme}
      >
        <MainProgress>
          <Snackbars
            SnackbarProps={{
              IconProps: {
                size: 'large'
              }
            }}
          >
            <MainLoader>
              <Confirm>
                <Elements stripe={stripePromise}>
                  <Library>
                    <App />
                  </Library>
                </Elements>
              </Confirm>
            </MainLoader>
          </Snackbars>
        </MainProgress>
      </Theme>
    </Style>
  </BrowserRouter>
);

// Todo 
// Make it work properly 
// at some moment 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
