import React from 'react';

import { is } from '@onesy/utils';
import { Select } from '@onesy/ui-react';
import { ISelect } from '@onesy/ui-react/Select/Select';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {
    '& .onesy-Select-input': {
      whiteSpace: 'nowrap',
      overflow: 'auto hidden',

      '&::-webkit-scrollbar': {
        height: 0,
        width: 0
      }
    }
  }
}), { name: 'onesy-Select' });

const Element: React.FC<ISelect> = React.forwardRef((props, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const [width, setWidth] = React.useState();

  const refs = {
    root: React.useRef<HTMLElement>(undefined),
    width: React.useRef(width)
  };

  refs.width.current = width;

  return (
    <Select
      ref={(item: any) => {
        if (ref) {
          if (is('function', ref)) ref(item);
          ref.current = item;
        }

        refs.root.current = item;

        if (refs.width.current === undefined) {
          setTimeout(() => {
            setWidth(item?.clientWidth);
          }, 140);
        }
      }}

      version='outlined'

      size='small'

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      MenuProps={{
        portal: true,

        size: 'regular',

        style: {
          width
        }
      }}

      className={classNames([
        className,
        classes.root
      ])}

      clear={false}

      {...other}

      WrapperProps={{
        ...other.WrapperProps,

        className: classNames([
          other.WrapperProps?.className,

          classes.wrapper
        ])
      }}
    />
  );
});

export default Element;
