import React from 'react';

import { useConfirm, useSnackbars } from '@onesy/ui-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IUserGroup } from '@onesy/api';

import IconMaterialGroup from '@onesy/icons-material-rounded-react/IconMaterialGroupW100';

import { FormUserGroup, ItemCard, ItemMenu } from 'ui';
import { AppService, UserGroupService } from 'services';
import { getDate, getErrorMessage } from 'utils';

export interface IEmployeeGroupItem extends IBaseElement {
  object: IUserGroup;
}

const Element: React.FC<IEmployeeGroupItem> = React.forwardRef((props, ref: any) => {
  const {
    object,

    ...other
  } = props;

  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const active = object?.active;
  const users = object?.users || [];

  const onOpen = React.useCallback(() => {
    AppService.pages.add.emit({
      open: true,

      children: (
        <FormUserGroup
          object={object}

          onConfirm={() => UserGroupService.refetch()}
        />
      )
    });
  }, [object]);

  const onActive = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `${active ? 'Inactivating' : 'Activating'} ${object.name} employee group`
    });

    if (!confirmed) return;

    const result = await UserGroupService.update(object.id, {
      active: !active
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${object.name} employee group ${active ? 'inactivated' : 'activated'}`
      });
    }

    // refresh 
    UserGroupService.refetch();
  }, [object, active]);

  const onRemove = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `Removing ${object.name} employee group`
    });

    if (!confirmed) return;

    const result = await UserGroupService.remove(object.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${object.name} employee group removed`
      });
    }

    // refresh 
    UserGroupService.refetch();
  }, [object, active]);

  return (
    <ItemCard
      ref={ref}

      items_start={[
        {
          icon: IconMaterialGroup,
          onClick: onOpen
        },
        {
          name: 'Name',
          value: object.name,
          onClick: onOpen
        },
        {
          name: 'Users',
          value: users.map((item: any) => item.name).join(', ') || 'No users',
          propsValue: {
            style: {
              maxWidth: 240,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }
        },
        {
          name: 'Access',
          value: active ? 'Active' : 'Inactive',
          propsValue: {
            color: active ? 'inherit' : 'error'
          }
        }
      ]}

      items_end={[
        {
          name: 'Added at',
          value: getDate(object?.added_at!),
          align: 'flex-end',
          propsValue: {
            whiteSpace: 'nowrap'
          }
        },
        {
          value: (
            <ItemMenu
              items={[
                { name: 'Open', onClick: onOpen },
                { name: active ? 'Inactivate' : 'Activate', onClick: onActive },
                { name: 'Remove', onClick: onRemove, props: { color: 'error' } }
              ]}
            />
          )
        }
      ]}

      {...other}
    />
  )
});

export default Element; 