import OnesySubscription from '@onesy/subscription';
import { Booking } from '@onesy/api';

import { BaseService } from './base';
import { IQuerySubscription } from 'types';

class BookingService extends BaseService<Booking> {
  public queryObjectsPublic = new OnesySubscription<IQuerySubscription>();

  get paginationLimit() {
    return 50;
  }

  public constructor() {
    super('bookings');

    this.queryObjectsInit(this.queryObjectsPublic, 'queryPostPublic');
  }

}

export default new BookingService();
