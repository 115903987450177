import React from 'react';

import { Divider } from '@onesy/ui-react';
import { IDivider } from '@onesy/ui-react/Divider/Divider';
import { classNames, style } from '@onesy/style-react';

const useStyle = style(theme => ({
  root: {
    '&.onesy-Divider-root': {
      opacity: theme.palette.light ? 0.06 : 0.15
    }
  },

  smaller: {
    '&.onesy-Divider-root': {
      width: '40%',
      alignSelf: 'center',
      justifySelf: 'center'
    }
  },

  size_large: {
    '&.onesy-Divider-root': {
      marginBlock: 70
    }
  },

  size_regular: {
    '&.onesy-Divider-root': {
      marginBlock: 40
    }
  },

  size_small: {
    '&.onesy-Divider-root': {
      marginBlock: 10
    }
  }
}), { name: 'onesy-Divider' });

const Element: React.FC<IDivider> = React.forwardRef((props, ref: any) => {
  const {
    color,

    size = 'regular',

    smaller,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <Divider
      color='inverted'

      className={classNames([
        classes.root,
        className,
        classes[`size_${size}`],
        smaller && classes.smaller
      ])}

      {...other}
    />
  );
});

export default Element;
