import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, Medias, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { OnesyDate } from '@onesy/date';
import { IMedia } from '@onesy/api';

import IconMaterialFolderOpen from '@onesy/icons-material-rounded-react/IconMaterialFolderOpenW100';

import { AudioRecorder, Color, ModalForm, SelectAColor, SmartTextField, SpeechToText, TextToSpeech, useLibrary } from 'ui';
import { AppService, AuthService, MediaService, NoteService } from 'services';
import { audioFix, getDate, getElementText, getErrorMessage, mediasToValue } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();
  const media = useLibrary();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);
  const [, setMode] = React.useState('read');

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name ? textToInnerHTML(object?.name) : '',
        required: true
      },
      'value': {
        name: 'Description',
        value: object?.value?.[0]?.value ? textToInnerHTML(object?.value?.[0]?.value) : ''
      },
      'color': {
        name: 'Color',
        value: object?.color || null,
      },
      'media': {
        name: 'Media',
        value: [...object?.media || []],
        is: 'array',
        of: 'object'
      }
    },
    valueDefault: {
      name: object?.name,
      value: object?.value?.[0]?.value,
      media: []
    }
  });

  const refs = {
    name: React.useRef<HTMLElement>(undefined),
    value: React.useRef<HTMLElement>(undefined),
    form: React.useRef(form),
    original: React.useRef<any>(undefined)
  };

  refs.form.current = form;

  const init = React.useCallback(() => {
    if (object?.id) {
      form.onChange('media', [...(object.media || [])]);
    }
  }, [form, object]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      name: innerHTMLToText(refs.form.current.value.name),

      value: [
        {
          version: 'type',
          value: innerHTMLToText(refs.form.current.value.value)
        }
      ],

      color: refs.form.current.value.color,

      media: refs.form.current.value.media
    };

    const result = !object?.id ? await NoteService.add(body) : await NoteService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Note ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onAddMediaConfirm = React.useCallback((value: IMedia[]) => {
    const valueNew = [...value].map(media => mediasToValue({
      id: media.id,
      name: media.name,
      mime: media.mime,
      meta: media.meta,
      versions: media.versions,
      thumbnails: media.thumbnails,
      added_at: OnesyDate.milliseconds
    }));

    form.onChange('media', valueNew);
  }, [form]);

  const onOpenMedia = React.useCallback(() => {
    media.open({
      selected: (form.values['media']?.value || []).filter(Boolean),
      multiple: true,
      onConfirm: onAddMediaConfirm
    });
  }, [media, onAddMediaConfirm]);

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const {
      blob: media,
      duration
    } = await audioFix(value);

    console.log('onAudioRecorderConfirm audioFix', media, duration);

    if (!media) {
      console.log('Media make error', media, duration);

      snackbars.add({
        primary: 'Voice record issue. Your device might not support voice recording properly',
        color: 'error'
      });

      setLoading(false);

      return;
    }

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${getDate(undefined, 'entire')}`;

    // meta
    const meta: any = {};

    if (duration) meta.duration = duration;

    const result = await MediaService.add({
      name,

      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the note
      const medias = refs.form.current.values.media.value || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: OnesyDate.milliseconds
      }));

      refs.form.current.onChange('media', medias);
    }

    setLoading(false);
  }, [onConfirm]);

  const onSpeechToTextStart = React.useCallback(() => {
    refs.original.current = textToInnerHTML(refs.form.current.values.value.value);
  }, []);

  const onSpeechToTextStop = React.useCallback(() => {
    refs.original.current = '';
  }, []);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onSpeechToTextUpdate = React.useCallback((valueNew: any) => {
    refs.value.current!.innerHTML = refs.original.current;

    const div = window.document.createElement('div');

    div.innerHTML = valueNew;

    refs.value.current!.append(div);

    refs.form.current.onChange('value', innerHTMLToText(refs.value.current!.innerHTML));
  }, []);

  const modal: any = {
    write: (
      <Line
        gap={5}

        flex

        fullWidth
      >
        <Line
          gap={2}

          justify='unset'

          align='unset'

          flex

          fullWidth
        >
          <SmartTextField
            ref={refs.value}

            placeholder='Note'

            valueDefault={textToInnerHTML(form.values.value.value)}

            onChange={(valueNew: string) => form.onChange('value', valueNew)}

            additional={{
              version: 'b1'
            }}

            edit

            multiline

            mention
          />
        </Line>

        {!!form.values.media.value?.length && (
          <Medias
            values={form.values.media.value?.map((item: any) => ({
              value: item
            }))}

            noName
          />
        )}
      </Line>
    )
  };

  const nameInput = (
    <SmartTextField
      ref={refs.name}

      placeholder='Name'

      valueDefault={textToInnerHTML(form.values.name.value)}

      onChange={(valueNew: string) => form.onChange('name', textToInnerHTML(valueNew))}

      additional={{
        version: 'h3'
      }}

      style={{
        whiteSpace: 'normal'
      }}
    />
  );

  return (
    <ModalForm
      {...props}

      nameAdd={nameInput}

      nameUpdate={nameInput}

      object={object}

      add

      {...modal}

      onSubmit={onNext}

      onNext={onNext as any}

      onChangeMode={onChangeMode}

      onClose={onClose}

      loading={loading === true}

      NextProps={{
        disabled: loading === 'voice recording'
      }}

      footerLeftEnd={<>
        <SelectAColor
          value={form.values['color'].value}

          error={!!form.values['color'].error}

          helperText={form.values['color'].error}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      </>}

      footerRightStart={<>
        <SpeechToText
          onData={onSpeechToTextUpdate}

          onChange={onSpeechToTextUpdate}

          onStart={onSpeechToTextStart}

          onStop={onSpeechToTextStop}

          language={signedIn?.organization?.personalization?.settings?.languages?.app}
        />

        {!!form.value.value?.length && (
          <TextToSpeech
            read={getElementText(textToInnerHTML(form.value.value))}

            language={signedIn?.organization?.personalization?.settings?.languages?.app}
          />
        )}

        <AudioRecorder
          onConfirm={(value: Blob) => onAudioRecorderConfirm(value)}

          disabled={loading === 'voice recording'}
        />

        <Tooltip
          name='Add media'
        >
          <IconButton
            onClick={onOpenMedia}
          >
            <IconMaterialFolderOpen
              size='large'
            />
          </IconButton>
        </Tooltip>
      </>}

      footerLeftStartRead={<>
        {object?.color && (
          <Color
            color={object.color}
          />
        )}

        {object && (
          <Type
            version='b3'
          >
            {getDate(object?.added_at, 'entire')}
          </Type>
        )}
      </>}

      footerRightStartRead={<>
        {!!form.value.value?.length && (
          <TextToSpeech
            read={getElementText(textToInnerHTML(form.value.value))}
          />
        )}
      </>}
    />
  );
});

export default Element;
