import React from 'react';
import HelmetElement from 'react-helmet';

import { cleanValue } from '@onesy/utils';
import { IBaseElement } from '@onesy/ui-react/types';

export interface IMeta extends IBaseElement {
  app?: string;

  name?: string;
}

const Helmet: any = HelmetElement;

const Element: React.FC<IMeta> = props => {
  const {
    name: name_,

    app = 'onesy',

    children
  } = props;

  const name = React.useMemo(() => {
    if (name_) return name_;

    if (app === 'onesy') return app;

    if (app === 'personal-trainer') return 'Personal Trainer';

    if (app === 'url-shortener') return 'URL shortener';

    return cleanValue(app, { capitalize: true });
  }, [name_, app]);

  return (
    <Helmet>
      <title>{name || 'onesy'}</title>

      {children}
    </Helmet>
  );
};

export default Element;
