import React from 'react';

import { cleanValue, is, unique } from '@onesy/utils';
import { Line, Type } from '@onesy/ui-react';
import { useOnesyTheme } from '@onesy/style-react';
import { themesList } from '@onesy/themes';

import IconMaterialOpenInNew from '@onesy/icons-material-rounded-react/IconMaterialOpenInNewW100';

import { Button, ItemCardBox, Items, Search } from 'ui';
import config from 'config';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    selected: selectedProps,

    onSelect: onSelectProps
  } = props;

  const theme = useOnesyTheme();

  const [search, setSearch] = React.useState<any>({});
  const [selected, setSelected] = React.useState<any>(selectedProps);

  const objects = React.useMemo(() => {
    const items = [...themesList]
      .filter(item => !Object.values(search) || (
        (!search.name || item.name.toLowerCase().includes(search.name.toLowerCase())) &&
        (!search.categories?.length || search.categories.some(itemCategory => item.categories.includes(itemCategory))) &&
        (!search.type?.length || search.type.some(itemType => item.type === itemType))
      ));

    return items;
  }, [themesList, search]);

  React.useEffect(() => {
    if (selected !== selectedProps) setSelected(selectedProps);
  }, [selectedProps]);

  const types = React.useMemo(() => {
    const items = [];

    objects.forEach(item => items.push(item.type));

    return unique(items).map(item => ({
      name: cleanValue(item, { capitalize: true }),
      value: item
    }));
  }, []);

  const categories = React.useMemo(() => {
    const items = [];

    objects.forEach(item => items.push(...item.categories));

    return unique(items).map(item => ({
      name: cleanValue(item, { capitalize: true }),
      value: item
    }));
  }, [objects]);

  const onSearch = React.useCallback((valueNew: any) => {
    setSearch(valueNew);
  }, []);

  const onSelect = React.useCallback((object: any) => {
    const valueNew = { id: object.id, name: object.name, image: object.image, type: object.type };

    setSelected(valueNew);

    if (is('function', onSelectProps)) onSelectProps(valueNew);
  }, [onSelectProps]);

  const onOpen = React.useCallback((object: any) => {
    const websitesURL = new URL(config.value.apps.websites.url);

    window.open(`${websitesURL.protocol}//${object.id}.${websitesURL.host}?preview=true`, '_blank');
  }, []);

  return (
    <Line
      ref={ref}

      gap={1}

      fullWidth
    >
      <Line
        gap={1}

        direction='row'

        wrap='wrap'

        justify='space-between'

        align='center'

        fullWidth
      >
        <Line
          gap={2}

          direction='row'

          align='center'
        >
          <Type
            version='h2'

            align='start'
          >
            Designs
          </Type>
        </Line>

        <Line
          gap={1}

          direction='row'

          align='center'
        >

        </Line>
      </Line>

      <Line
        gap={1}

        direction='row'

        justify='flex-start'

        align='center'

        fullWidth

        className='onesy-overflow-x'
      >
        <Search
          onSearch={onSearch}

          search={[
            {
              name: 'Name',
              property: 'name'
            },

            {
              name: 'Category',
              type: 'select',
              property: 'categories',
              options: categories,
              multiple: true
            },

            {
              name: 'Type',
              type: 'select',
              property: 'type',
              options: types,
              multiple: true
            }
          ]}

          timeout={0}
        />
      </Line>

      <Line
        gap={0}

        direction='column'

        justify='unset'

        align='unset'

        flex

        fullWidth
      >
        <Items>
          {objects.map(object => (
            <ItemCardBox
              name={object.name}

              image={(theme.palette.light ? object.image : object.imageDark) || object.image}

              onOpen={() => onSelect(object)}

              selected={selected?.id === object.id}

              endFooter={(
                <Button
                  version='outlined'

                  color='inherit'

                  onClick={() => onOpen(object)}

                  size='regular'

                  end={(
                    <IconMaterialOpenInNew
                      size='regular'
                    />
                  )}
                >
                  Preview
                </Button>
              )}

              cover
            />
          ))}
        </Items>
      </Line>
    </Line>
  );
});

export default Element; 
