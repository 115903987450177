import React from 'react';

import { getID, getObjectValue, innerHTMLToText, is, isValid, textToInnerHTML } from '@onesy/utils';
import { Image, Line, Link, Switch, Type, useConfirm, useForm, useSnackbars } from '@onesy/ui-react';
import { useOnesyTheme } from '@onesy/style-react';
import { ValidationError } from '@onesy/errors';
import { themesMap } from '@onesy/themes';

import IconMaterialOpenInNew from '@onesy/icons-material-rounded-react/IconMaterialOpenInNewW100';
import IconMaterialPalette from '@onesy/icons-material-rounded-react/IconMaterialPaletteW100';
import IconMaterialMenu from '@onesy/icons-material-rounded-react/IconMaterialMenuW100';
import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialStyle from '@onesy/icons-material-rounded-react/IconMaterialStyleW100';
import IconMaterialWebStories from '@onesy/icons-material-rounded-react/IconMaterialWebStoriesW100';
import IconMaterialAnalytics from '@onesy/icons-material-rounded-react/IconMaterialAnalyticsW100';

import { Action, Button, Divider, Input, Inputs, ModalForm, Palette, PreviewTheme, SelectMedia, SEO, TextField, Themes } from 'ui';
import { AppService, WebsiteService } from 'services';
import { getErrorMessage, getMediaUrl, mediaToValue } from 'utils';
import config from 'config';
import { Analytics, Menus, Pages } from './Website';

const Element = React.forwardRef((props: any) => {
  const {
    object,

    onConfirm
  } = props;

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const [tab, setTab] = React.useState('Analytics');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object.name,
        min: 1,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'url': {
        name: 'URL',
        value: object.url,
        method: value => {
          const valid = !value?.length || isValid('domain-name', value);

          if (!valid) throw new ValidationError(`URL has to be a valid URL name. Only letters, optionally dashes.`);
        }
      },
      'description': {
        name: 'Description',
        value: object.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'image': {
        name: 'Image',
        value: object.image
      },

      'active': {
        name: 'Active',
        value: object?.active || false,
        is: 'boolean'
      },

      'theme': {
        name: 'Theme',
        value: object.theme,
        is: 'object',
        required: true
      },

      'domain': {
        name: 'Domain',
        is: 'string',
        isValid: 'url'
      },

      'options.header.menu': {
        name: 'Menu',
        value: getObjectValue(object, 'options.header.menu') || [],
        is: 'array'
      },

      'options.footer.title': {
        name: 'Title',
        value: getObjectValue(object, 'options.footer.title') || '',
        min: 1,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'options.footer.description': {
        name: 'Description',
        value: getObjectValue(object, 'options.footer.description') || '',
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'options.footer.meta': {
        name: 'Meta',
        value: getObjectValue(object, 'options.footer.meta') || '',
        min: 1,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'options.footer.action': {
        name: 'Action',
        value: getObjectValue(object, 'options.footer.action') || {
          id: getID(),
          name: 'Contact us',
          version: 'email',
          value: 'example@onesy.me'
        },
        is: 'object'
      },
      'options.footer.social_media': {
        name: 'Social media',
        value: getObjectValue(object, 'options.footer.social_media') || [
          { name: 'Instagram', type: 'instagram', url: '' },
          { name: 'Tiktok', type: 'tiktok', url: '' },
          { name: 'Youtube', type: 'youtube', url: '' },
          { name: 'Linkedin', type: 'linkedin', url: '' },
          { name: 'Facebook', type: 'facebook', url: '' }
        ],
        is: 'array'
      },

      'options.booking': {
        name: 'Booking',
        value: getObjectValue(object, 'options.booking') || false,
        is: 'boolean'
      },

      'options.chat': {
        name: 'Chat',
        value: getObjectValue(object, 'options.chat') || false,
        is: 'boolean'
      },

      'options.logos.logo_light': {
        name: 'Logo light',
        value: getObjectValue(object, 'options.logos.logo_light')
      },
      'options.logos.favicon_light': {
        name: 'Favicon light',
        value: getObjectValue(object, 'options.logos.favicon_light')
      },

      'options.theme.palette.image': {
        name: 'Theme image',
        value: getObjectValue(object, 'options.theme.palette.image')
      },

      'options.theme.palette.color.primary.main': {
        name: 'Theme color primary',
        value: getObjectValue(object, 'options.theme.palette.color.primary.main'),
        is: 'string'
      },
      'options.theme.palette.color.secondary.main': {
        name: 'Theme color secondary',
        value: getObjectValue(object, 'options.theme.palette.color.secondary.main'),
        is: 'string'
      },
      'options.theme.palette.color.tertiary.main': {
        name: 'Theme color tertiary',
        value: getObjectValue(object, 'options.theme.palette.color.tertiary.main'),
        is: 'string'
      },
      'options.theme.palette.color.quaternary.main': {
        name: 'Theme color quaternary',
        value: getObjectValue(object, 'options.theme.palette.color.quaternary.main'),
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form),
    theme: React.useRef<any>(object?.theme)
  };

  refs.form.current = form;

  refs.theme.current = form.value.theme || object?.theme;

  const onepage = form.value.theme?.type === 'onepage';

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (body.url === object.url) delete body.url;

    const result = await WebsiteService.update(object.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Website updated`
      });

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onVisible = React.useCallback(async () => {
    const active = refs.form.current.values.active.value;

    const confirmed = await confirm.open({
      name: active ? 'Making website private' : 'Publishing the website'
    });

    if (!confirmed) return;

    setLoading(true);

    const result = await WebsiteService.update(object.id, {
      active: !active
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Website ${active ? 'made private' : 'published'}`
      });

      refs.form.current.onChange('active', !active);

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [object, onConfirm]);

  const onChangePalette = React.useCallback((valueNew: any) => {
    refs.form.current.onChange([
      ['options.theme.palette.image', mediaToValue(valueNew.image)],
      ['options.theme.palette.color.primary.main', valueNew.primary],
      ['options.theme.palette.color.secondary.main', valueNew.secondary],
      ['options.theme.palette.color.tertiary.main', valueNew.tertiary],
      ['options.theme.palette.color.quaternary.main', valueNew.quaternary]
    ]);
  }, []);

  const onRefreshPalette = React.useCallback(() => {
    const themeObjectNew = themesMap[refs.theme.current.id];

    if (themeObjectNew) {
      const color = themeObjectNew.options.theme.palette.color;

      refs.form.current.onChange([
        ['options.theme.palette.color.primary.main', color.primary.main],
        ['options.theme.palette.color.secondary.main', color.secondary.main],
        ['options.theme.palette.color.tertiary.main', color.tertiary.main],
        ['options.theme.palette.color.quaternary.main', color.quaternary.main]
      ]);
    }
  }, []);

  const onSelectTheme = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('theme', valueNew);
  }, []);

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onOpenLink = React.useCallback(() => {
    const websitesURL = new URL(config.value.apps.websites.url);

    window.open(`${websitesURL.protocol}//${object.url}.${websitesURL.host}`, '_blank');
  }, [object]);

  const value = form.value;

  const social_media = value.options?.footer?.social_media || [];

  const textFieldProps: any = {
    fullWidth: true
  };

  const dividerProps: any = {
    size: 'small'
  };

  const ui = {
    'Analytics': (
      <Analytics
        object={object}
      />
    ),

    'Info': (
      <Inputs>
        <SEO
          name={value.name}

          description={value.description}

          url={value.url}

          image={value.image}
        />

        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            value={form.values['name'].value || ''}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew)}

            {...textFieldProps}
          />
        </Input>

        <Input
          name='URL'

          description={(
            <Type
              version='b2'
            >
              Free subdomain name for your website, ie. <b>{form.values['url'].value}</b>.websites.onesy.me <br />
              You can add your own domain later
            </Type>
          )}
        >
          <TextField
            placeholder='ana'

            value={form.values['url'].value || ''}

            error={form.values['url'].error}

            helperText={form.values['url'].error}

            onChange={(valueNew: any) => form.onChange('url', valueNew)}

            {...textFieldProps}
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            value={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew))}

            minRows={4}

            maxRows={4}

            multiline

            edit

            {...textFieldProps}
          />
        </Input>

        <Input
          name='Image'

          description='Used as a thumbnail for your website and Google SEO'
        >
          {form.values.image.value && (
            <Image
              src={form.values.image.value.url}

              style={{
                maxHeight: 140
              }}
            />
          )}

          <SelectMedia
            value={form.values.image.value}

            onChange={(valueNew: any) => form.onChange('image', mediaToValue(valueNew))}
          />
        </Input>

        <Divider
          {...dividerProps}
        />

        <Inputs
          name='Integrations'

          description='Integrate booking, chat into your website'
        >
          <Input
            name='Booking'

            description='If you want to integrate booking into your website, for your visitors to make bookings online'

            startName={(
              <Switch
                value={form.values['options.booking'].value}

                onChange={(valueNew: any) => form.onChange('options.booking', valueNew)}

                size='regular'
              />
            )}
          />

          <Input
            name='Chat'

            description='If you want to integrate chat into your website, for your visitors to contact you'

            startName={(
              <Switch
                value={form.values['options.chat'].value}

                onChange={(valueNew: any) => form.onChange('options.chat', valueNew)}

                size='regular'
              />
            )}
          />
        </Inputs>

        <Divider
          {...dividerProps}
        />

        <Input
          name='Domain'

          description={(
            <Type
              version='b2'
            >
              Domain you want to connect to your website. <br /><br />

              Whereever you bought your domain, go the your domain provider, and create a new CNAME (Alias) records that points to your website's subdomain, ie. CNAME www -&gt; <b>{form.values.url.value}</b>.onesy.me.<br /><br />

              If you need more help, please contact our support at <Link href='https://onesy.me' target='_blank' version='b2'>onesy.me</Link>.
            </Type>
          )}
        >
          <TextField
            placeholder='mywebsite.com'

            value={form.values.domain?.value || ''}

            onChange={(valueNew: string) => form.onChange('domain', valueNew)}

            error={!!form.values.domain.error}

            helperText={form.values.domain.error}

            {...textFieldProps}
          />
        </Input>

        <Divider
          {...dividerProps}
        />

        <Input
          name='Visibility'

          description={`Published website is visible to anyone, private websites will be hidden from everyone`}

          footer={(
            <Button
              version='outlined'

              color={form.values.active.value ? 'warning' : 'success'}

              onClick={onVisible}
            >
              {form.values.active.value ? 'Private website' : 'Publish website'}
            </Button>
          )}
        />
      </Inputs>
    ),

    'Branding': (
      <Inputs>
        <Line
          gap={2}

          fullWidth
        >
          <Line
            gap={2}

            direction={{
              default: 'row',
              1100: 'column'
            }}

            fullWidth
          >
            <Line
              gap={1.5}
            >
              <Type
                version='t2'
              >
                Logo
              </Type>

              <SelectMedia
                value={form.values['options.logos.logo_light'].value}

                onChange={(valueNew: any) => form.onChange('options.logos.logo_light', mediaToValue(valueNew))}
              />
            </Line>

            <PreviewTheme
              version={`${theme.palette.light ? 'light' : 'dark'}-preview`}

              url={getMediaUrl(form.values['options.logos.logo_light'].value)}

              example='logo'
            />
          </Line>

          <Line
            gap={2}

            direction={{
              default: 'row',
              1100: 'column'
            }}

            fullWidth
          >
            <Line
              gap={1.5}
            >
              <Type
                version='t2'
              >
                Favicon
              </Type>

              <SelectMedia
                value={form.values['options.logos.favicon_light'].value}

                onChange={(valueNew: any) => form.onChange('options.logos.favicon_light', mediaToValue(valueNew))}
              />
            </Line>

            <PreviewTheme
              version={`${theme.palette.light ? 'light' : 'dark'}-preview`}

              url={getMediaUrl(form.values['options.logos.favicon_light'].value)}

              example='favicon'
            />
          </Line>
        </Line>

        <Input
          name='Colors'
        >
          <Palette
            value={{
              image: form.values[`options.theme.palette.image`].value,
              primary: form.values[`options.theme.palette.color.primary.main`].value || '#ffffff',
              secondary: form.values[`options.theme.palette.color.secondary.main`].value || '#ffffff',
              tertiary: form.values[`options.theme.palette.color.tertiary.main`].value || '#ffffff',
              quaternary: form.values[`options.theme.palette.color.quaternary.main`].value || '#ffffff'
            }}

            onChange={onChangePalette}

            onReset={onRefreshPalette}
          />
        </Input>
      </Inputs>
    ),

    'Pages': (
      <Pages
        website={{
          ...object,

          ...form.value
        }}
      />
    ),

    'Menus': (
      <Inputs>
        <Inputs
          name='Header'

          description='Header information'
        >
          <Menus
            form={form}
          />
        </Inputs>

        <Inputs
          name='Footer'

          description='Footer information'
        >
          <Input
            name='Title'
          >
            <TextField
              placeholder='Title'

              value={form.values['options.footer.title'].value || ''}

              error={form.values['options.footer.title'].error}

              helperText={form.values['options.footer.title'].error}

              onChange={(valueNew: any) => form.onChange('options.footer.title', valueNew)}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Description'
          >
            <TextField
              placeholder='Description'

              value={textToInnerHTML(form.values['options.footer.description'].value || '')}

              error={form.values['options.footer.description'].error}

              helperText={form.values['options.footer.description'].error}

              onChange={(valueNew: any) => form.onChange('options.footer.description', innerHTMLToText(valueNew))}

              minRows={4}

              multiline

              edit

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Meta'

            description='Text at the end of the footer'
          >
            <TextField
              placeholder='Text'

              value={form.values['options.footer.meta'].value || ''}

              error={form.values['options.footer.meta'].error}

              helperText={form.values['options.footer.meta'].error}

              onChange={(valueNew: any) => form.onChange('options.footer.meta', valueNew)}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Action'

            description='Button, a call-to-action'
          >
            <Action
              item={form.values['options.footer.action'].value || {}}

              form={form}

              prefix='options.footer.action'
            />
          </Input>

          <Input
            name='Social media'
          >
            {social_media.map((item, index: number) => (
              <TextField
                key={item.name}

                name={item.name}

                value={item.url}

                onChange={(valueNew: any) => form.onChange(`options.footer.social_media`, valueNew, `${index}.url`)}

                type='url'

                clear

                fullWidth
              />
            ))}
          </Input>
        </Inputs>
      </Inputs>
    ),

    'Design': (
      <Inputs>
        <Themes
          selected={form.values.theme.value}

          onSelect={onSelectTheme}
        />
      </Inputs>
    )
  };

  const tabs = React.useMemo(() => {

    return [
      { name: 'Analytics', Icon: IconMaterialAnalytics },
      { name: 'Info', Icon: IconMaterialInfo },
      { name: 'Branding', Icon: IconMaterialPalette },
      { name: 'Pages', Icon: IconMaterialWebStories },
      { name: 'Menus', Icon: IconMaterialMenu, props: { disabled: onepage } },
      { name: 'Design', Icon: IconMaterialStyle }
    ];
  }, [onepage]);

  return (
    <ModalForm
      {...props}

      name={form.values.name.value || 'Website'}

      tab={tab}

      onChangeTab={onChangeTab}

      tabs={tabs}

      onClose={onClose}

      onNext={onNext}

      loading={loading}

      footer={!['Analytics', 'Pages'].includes(tab)}

      startHeaderRight={<>
        {form.value.active && (
          <Button
            version='outlined'

            color='inherit'

            onClick={onOpenLink}

            size='regular'

            end={(
              <IconMaterialOpenInNew
                size='regular'
              />
            )}
          >
            Open
          </Button>
        )}
      </>}

      add

      maxHeight
    >
      {ui[tab] || null}
    </ModalForm>
  );
});

export default Element;
