import React from 'react';
import { useNavigate } from 'react-router-dom';

import { textToInnerHTML, to as to_ } from '@onesy/utils';
import { Badge, Expand, List, ListItem, ListSubheader, Menu, Type, useSnackbars, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { ISwitch } from '@onesy/sdk/other';

import IconMaterialImagesmode from '@onesy/icons-material-rounded-react/IconMaterialImagesmodeW100';
import IconMaterialAccountBalanceWallet from '@onesy/icons-material-rounded-react/IconMaterialAccountBalanceWalletW100';
import IconMaterialCloud from '@onesy/icons-material-rounded-react/IconMaterialCloudW100';
import IconMaterialSettings from '@onesy/icons-material-rounded-react/IconMaterialSettingsW100';
import IconMaterialOutput from '@onesy/icons-material-rounded-react/IconMaterialOutputW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';

import { Avatar, Divider } from 'ui';
import { AuthService } from 'services';
import { getDeviceAndLocation, getErrorMessage, getSettings } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    '&.onesy-Avatar-root.onesy-Button-root': {
      cursor: 'pointer',
      userSelect: 'none'
    }
  },

  avatarOpen: {
    outline: `1px solid ${theme.palette.text.default.secondary}`
  },

  menu: {
    maxHeight: '84vh',
    overflow: 'hidden auto',

    '&.onesy-Line-root': {
      width: '100vw',
      maxWidth: 270,
      borderRadius: theme.methods.shape.radius.value(1),
      boxShadow: `0px 4px 32px 0px rgba(${theme.palette.light ? '0, 0, 0' : '255, 255, 255'}, 0.04)`,
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[5]
    },

    '& .onesy-ListItem-middle .onesy-Type-root': {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    '& .onesy-ListItem-wrapper:not(.onesy-ListSubheader-root)': {
      background: 'none'
    }
  },

  menuSubheader: {
    '&.onesy-ListSubheader-root': {
      background: 'inherit',

      '& .onesy-Type-root': {
        fontWeight: 200
      }
    }
  },

  itemMain: {
    '& + .onesy-Divider-root': {
      marginTop: 0
    }
  },

  arrowDown: {
    transition: theme.methods.transitions.make('transform'),

    '&.open': {
      transform: 'rotate(-180deg)'
    }
  },

  expand: {
    width: '100%'
  },

  addColor: {
    color: theme.palette.light ? 'hsl(198.67deg 97.57% 54%)' : 'hsl(198.67deg 97.57% 84%)'
  },

  divider: {
    '&.onesy-Divider-root': {
      opacity: theme.palette.light ? 0.07 : undefined
    }
  }
}), { name: 'onesy-UserMenu' });

const UserMenu = React.forwardRef((props: any, ref: any) => {
  const { classes } = useStyle();

  const navigate = useNavigate();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);
  const snackbars = useSnackbars();

  const [open, setOpen] = React.useState(false);
  const [openOrganizations, setOpenOrganizations] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
    setOpenOrganizations(false);
  }, []);

  const onSwitch = React.useCallback(async (body_: { organization?: any; project?: any; }) => {
    setLoading(true);

    const body: Partial<ISwitch> = {
      ...(await getDeviceAndLocation())
    };

    if (body_.organization) body.organization = { id: body_.organization.id };

    if (body_.project) body.project = { id: body_.project.id };

    const result = await AuthService.switch(body as any);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${body.organization ? 'Organization' : 'Project'} switched`
      });

      const response = result.response.response;

      await AuthService.init(response);

      navigate('/');

      onClose();
    }

    setLoading(false);
  }, [onClose]);

  const to = React.useCallback((value: string) => {
    navigate(value);

    onClose();
  }, []);

  const onOrganizationsToggle = React.useCallback(() => {
    setOpenOrganizations(previous => !previous);
  }, []);

  const getUserOrganization = (organization: any) => {
    const userOrganizations = signedIn.organizations;

    return userOrganizations.find(item => item.id === organization.id);
  };

  const onSignOut = React.useCallback(async () => {
    await AuthService.signOut();
  }, []);

  const user = signedIn?.user;

  const organizations = signedIn?.organizations || [];

  const organizationsMultiple = organizations.length > 1;

  const organizationSignedIn = organizations.find((item: any) => item.signed_in);

  const organization = signedIn?.organization;

  const userOrganization = organizations?.find((item: any) => item.id === organization.id);

  const organizationsOther = (organizations || []).filter((item: any) => !item.signed_in);

  const info: any = userOrganization?.personalization || {};

  const name = textToInnerHTML(info?.name || user?.name || '');

  let initials = '';

  if (user) {
    const [firstName, lastName] = name;

    initials += firstName.charAt(0);

    initials += ((lastName ? lastName.charAt(0) : firstName.charAt(1)) || '');
  }

  initials = initials?.toLowerCase();

  const IconProps: any = {
    size: 'regular'
  };

  const TypeProps: any = {
    version: 'b2'
  };

  const ListItemProps: any = {
    color: 'primary',
    size: 'small'
  };

  const ListItemOrganizationProps: any = {
    ...ListItemProps
  };

  const logo = organizationSignedIn?.settings?.logos?.logo_light || organizationSignedIn?.settings?.logos?.favicon_light || organizationSignedIn?.settings?.logos?.logo_dark || organizationSignedIn?.settings?.logos?.favicon_dark;

  const mediaUsage: any = {
    used: signedIn?.organizationPlan?.used?.media?.total || 0,
    provided: signedIn?.organizationPlan?.provided?.media?.total || 0
  };

  const dividerProps: any = {
    size: 'small',
    Component: 'li'
  };

  const organizationsUI = <>
    <ListSubheader
      {...ListItemProps}

      className={classNames([
        ListItemProps?.className,
        classes.menuSubheader
      ])}
    >
      <Type
        version='b3'

        priority='secondary'
      >
        Organizations
      </Type>
    </ListSubheader>

    <ListItem
      start={(
        <Avatar
          tonal

          color='quaternary'

          size='small'

          media={logo}
        >
          {organizationSignedIn?.name?.slice(0, 1)}
        </Avatar>
      )}

      primary={(
        <Type
          version='b2'
        >
          {organizationSignedIn?.name}
        </Type>
      )}

      end={organizationsMultiple ? (
        <IconMaterialKeyboardArrowDown
          className={classNames([classes.arrowDown, openOrganizations && 'open'])}
        />
      ) : undefined}

      onClick={organizationsMultiple ? onOrganizationsToggle : undefined}

      selected={openOrganizations}

      button={organizationsMultiple}

      {...ListItemOrganizationProps}
    />

    {organizationsMultiple && (
      <Expand
        in={openOrganizations}

        className={classes.expand}

        parent={{
          clientWidth: 240
        } as any}
      >
        <List
          paddingHorizontal='none'

          noBackground
        >
          {organizationsOther.map((item: any, index: number) => {
            const userOrganization = getUserOrganization(item);

            const notificationsUnread = (userOrganization?.projects as any[])?.reduce((result, item) => result += item.notifications_unread || 0, 0);

            return (
              <ListItem
                key={index}

                onClick={() => onSwitch({ organization: item })}

                start={(
                  <Badge
                    color='error'

                    indicator={!!notificationsUnread}
                  >
                    <Avatar
                      tonal

                      color='secondary'

                      size='small'

                      media={item?.settings?.images?.logo}
                    >
                      {item?.name?.slice(0, 1)}
                    </Avatar>
                  </Badge>
                )}

                primary={(
                  <Type
                    version='b2'
                  >
                    {item?.name}
                  </Type>
                )}

                shapePosition='both'

                button

                disabled={loading}

                {...ListItemOrganizationProps}
              />
            );
          })}
        </List>
      </Expand>
    )}
  </>;

  const menu = (
    <List
      className={classes.menu}
    >
      <ListItem
        color='primary'

        start={(
          <Avatar
            color='primary'

            media={getSettings(signedIn, 'settings.images.profile')}
          >
            {initials}
          </Avatar>
        )}

        primary={(
          <Type
            version='b1'

            dangerouslySetInnerHTML={{
              __html: name
            }}
          />
        )}

        secondary={(
          <Type
            version='b2'

            priority='secondary'
          >
            {user?.email}
          </Type>
        )}

        size='small'

        className={classes.itemMain}
      />

      <Divider
        {...dividerProps}
      />

      {organizationsUI}

      <Divider
        {...dividerProps}
      />

      <ListItem
        start={(
          <IconMaterialCloud
            tonal

            color='tertiary'
          />
        )}

        startAlign='center'

        primary={(
          <Type
            {...TypeProps}
          >
            Storage used: {((mediaUsage.used / mediaUsage.provided) * 100).toFixed(1)}% of {to_(mediaUsage.provided, 'size-format') as any}
          </Type>
        )}

        style={{
          cursor: 'default',
          userSelect: 'none'
        }}

        {...ListItemProps}
      />

      <Divider
        {...dividerProps}
      />

      <ListItem
        onClick={() => to('/library')}

        start={(
          <IconMaterialImagesmode
            {...IconProps}
          />
        )}

        primary={(
          <Type
            {...TypeProps}
          >
            Library
          </Type>
        )}

        button

        {...ListItemProps}
      />

      <Divider
        {...dividerProps}
      />

      {user?.is?.admin && <>
        <ListItem
          onClick={() => to('/organization/settings/subscription')}

          start={(
            <IconMaterialAccountBalanceWallet
              {...IconProps}
            />
          )}

          primary={(
            <Type
              {...TypeProps}
            >
              Manage subscription
            </Type>
          )}

          button

          {...ListItemProps}
        />

        <ListItem
          onClick={() => to('/organization/settings/info')}

          start={(
            <IconMaterialSettings
              {...IconProps}
            />
          )}

          primary={(
            <Type
              {...TypeProps}
            >
              Organization settings
            </Type>
          )}

          button

          {...ListItemProps}
        />

        <Divider
          {...dividerProps}
        />
      </>}

      <ListItem
        onClick={() => to('/settings')}

        start={(
          <IconMaterialSettings
            {...IconProps}
          />
        )}

        primary={(
          <Type
            {...TypeProps}
          >
            Account settings
          </Type>
        )}

        button

        {...ListItemProps}
      />

      <Divider
        {...dividerProps}
      />

      <ListItem
        start={(
          <IconMaterialOutput
            {...IconProps}
          />
        )}

        primary={(
          <Type
            {...TypeProps}
          >
            Sign out
          </Type>
        )}

        onClick={onSignOut}

        button

        {...ListItemProps}
      />
    </List>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      position='bottom'

      alignment='center'

      AppendProps={{
        offset: [0, 8],
        switch: false
      }}
    >
      <Avatar
        media={getSettings(signedIn, 'settings.images.profile')}

        className={classNames([
          classes.avatar,
          open && classes.avatarOpen
        ])}
      >
        {initials}
      </Avatar>
    </Menu>
  );
});

export default UserMenu;
