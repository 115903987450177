import React from 'react';

import { Line, Modal } from '@onesy/ui-react';
import { style } from '@onesy/style-react';

import { ReactComponent as IconLogo } from 'assets/svg/logo.svg';

import MainLoaderContext from './Context';

const useStyle = style(theme => ({
  root: {
    height: '100%'
  },

  initial: {
    position: 'fixed',
    inset: 0,
    height: '100vh',
    width: '100vw',
    background: theme.palette.background.default.primary,
    zIndex: theme.z_index.menu_modal
  },

  logo: {
    width: 'auto',
    height: 54,

    '@media only screen and (min-width: 1024px)': {
      '&': {
        height: 57
      }
    }
  }
}), { name: 'onesy-MainLoader' });

const MainLoader = React.forwardRef((props: any, ref: any) => {
  const {
    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const [init, setInit] = React.useState(false);
  // true by default
  const [openModal, setOpenModal] = React.useState(true);

  const refs = {
    init: React.useRef(init)
  };

  refs.init.current = init;

  const open = React.useCallback(() => {
    setOpenModal(true);
  }, []);

  const close = React.useCallback(() => {
    if (!refs.init.current) setInit(true);

    setOpenModal(false);
  }, []);

  const onEntered = React.useCallback(() => {
    if (!refs.init.current) setInit(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpenModal(false);
  }, []);

  const value = React.useMemo(() => {
    return {
      open,
      close
    };
  }, []);

  return (
    <MainLoaderContext.Provider
      value={value}
    >
      {!init && (
        <Line
          className={classes.initial}
        />
      )}

      <Modal
        tonal

        color='default'

        backgroundInvisible

        fullScreen

        freezeScroll

        disableKeyboardClose

        open={openModal}

        onClose={onClose}

        TransitionComponentProps={{
          onEntered
        }}

        {...other}
      >
        <Line
          gap={1.5}
          direction='column'
          align='center'
          justify='center'

          className={classes.root}
        >
          <IconLogo
            className={classes.logo}
          />
        </Line>
      </Modal>

      {children}
    </MainLoaderContext.Provider>
  );
});

export default MainLoader;
